import service from './service'

export default {
	getOrder: params => service.get(`back/report/getOrderDetail`, { params }),
	//取得訂單明細模組
	getOrderDetail: params =>
		service.get(`back/order/getOrderDetail`, { params }),
	//取得訂單卡片(多筆)
	getOrderListCard: params =>
		service.get(`back/order/getOrderCardList`, { params }),
	//列印訂單
	printOrder: params => service.post(`back/report/printOrder`, params),
	//補印發票
	printInvoice: params => service.post(`back/report/printInvoice`, params),
	// 沒有名字的狗傢伙－取得訂單卡片(單筆)
	getOrderCard: params => service.get(`back/order/getOrderCard`, { params }),
	// 檢查手機載具是否存在
	checkPhoneCarrier: params =>
		service.post(`back/order/checkPhoneCarrier`, params),

	//新增訂單細項
	addOrderDetail: params => service.post(`back/order/addOrderDetail`, params),
	//修改訂單
	updateOrder: params => service.put(`/back/order/updateOrderDetail`, params),
	//編輯取餐相關資訊
	updateOrderInfo: params => service.put(`/back/order/updateOrderInfo`, params),
	//刪除訂單細項
	removeOrder: params =>
		service.delete(`back/order/removeOrderDetail`, { params }),
	//一鍵完成訂單
	batchFinishOrder: params => service.post(`back/order/batchFinish`, params),

	//修改訂單狀態
	updateOrderStatus: formdata =>
		service.put(`back/order/updateOrderStatus`, formdata),
	//取消訂單
	remove: params => service.delete(`order/remove?id=${params}`),
	//取得顧客訂單
	getOrderByUser: params => service.post(`order/getOrderByUser`, params),
	// 沒有名字的狗傢伙－修改訂單明細數量
	updateOrderCount: params => service.put(`order/updateCount`, params),
	// 修改訂單付款狀態
	updateOrderPaid: params => service.put(`back/order/updateOrderPaid`, params),

	// 批量刪除訂單
	batchRemoveOrder: params =>
		service.delete(`back/report/batchRemoveOrder`, { params }),

	// 銷售排行榜
	getSalesRanking: params =>
		service.post(`back/report/getSalesRanking`, params),

	// 分類銷售 詳細
	getCategorySalesDetail: params =>
		service.post(`back/report/getCategorySalesDetail`, params),

	// 店家常用信箱
	getMailList: params => service.get(`back/report/mailList`, { params }),
	// 匯出報表到信箱
	exportReport: params =>
		service.post(`back/report/exportExcel`, params, { responseType: 'blob' }),

	// 更新外送訂單內容
	updateDeliveryOrder: params =>
		service.post(`back/deliveryOrder/updateDeliveryOrder`, params),

	orderReport: {
		getOrderPage: params =>
			service.post(`/back/store/report/order/page`, params),
		calculateTotalRevenue: params =>
			service.post(`/back/store/report/order/revenue`, params),
		getAvailablePayMethods: params =>
			service.post(`/back/store/report/order/payMethods`, params),
	},
	shiftReport: {
		page: params => service.post(`/back/store/report/shift/page`, params),
		finish: params => service.post(`/back/store/report/shift/finish`, params),
		batchRemove: params =>
			service.delete(`/back/store/report/shift/batchRemove`, { params }),
		recalculate: params =>
			service.post(`/back/store/report/shift/recalculate`, params),
		detail: params => service.post(`/back/store/report/shift/detail`, params),
		detailByDate: params =>
			service.post(`/back/store/report/shift/detailByDate`, params),
		print: params =>
			service.post(`/back/store/report/shift/print`, params),
	},
}
