import SearchBar from './SearchBar'
import PageTitle from './PageTitle'
import Card from './Card'
import TabHeader from './TabHeader'
import TabBody from './TabBody'
import FormRow from './FormRow'
import Counter from './Counter'
import MyFiles from './Files'
import Modal from './Modal'
import TextField from './TextField'
import Select from './Select'
import Textarea from './Textarea'
import Checkbox from './Checkbox'
import Switch from './Switch'
import Radio from './Radio'
import RowTitle from './RowTitle'
import RowText from './RowText'
import DeleteText from './DeleteText'
import SearchRow from './SearchRow'
import DatePicker from './DatePicker'
import ImgFile from './ImgFile'
import SearchFilter from './SearchFilter'
import BySlider from './BySlider'
import TimePicker from './TimePicker'
import TimeRangePicker from './TimeRangePicker'
import SortCheckboxTable from './SortCheckboxTable'
import CheckoutModal from './CheckoutModal'
import ChartTable from './ChartTable'
import InsideModal from './InsideModal'
import ReserveModal from './ReserveModal'
import AnnouncementDisplayModal from '@/components/AnnouncementDisplayModal'
import NewSwitch from '@/components/NewSwitch'
import ValidManagerCodeModal from '@/components/ValidManagerCodeModal.vue'
import FullHeightContainer from '@/components/FullHeightContainer.vue'
import NewCheckBox from '@/components/NewCheckBox'
import MyItemStockModal from '@/components/ItemStockModal.vue'
import SearchTextInput from '@/components/SearchTextInput.vue'
import LabelSelect from '@/components/LabelSelect.vue'
import LabelTextField from '@/components/LabelTextField.vue'
import CashDrawerRecordModal from '@/components/CashDrawerRecordModal.vue'
import MaintenanceModal from '@/components/MaintenanceModal.vue'
import NetworkDisconnectModal from '@/components/NetworkDisconnectModal.vue'
import AutoCompleteSelect from '@/components/AutoCompleteSelect.vue'
import QuantityCounter from '@/components/QuantityCounter.vue'

const components = [
	SearchBar,
	PageTitle,
	Card,
	TabHeader,
	TabBody,
	FormRow,
	Counter,
	MyFiles,
	Modal,
	TextField,
	Select,
	Textarea,
	Checkbox,
	Switch,
	Radio,
	RowTitle,
	RowText,
	DeleteText,
	SearchRow,
	DatePicker,
	ImgFile,
	SearchFilter,
	BySlider,
	TimePicker,
	TimeRangePicker,
	SortCheckboxTable,
	CheckoutModal,
	ChartTable,
	InsideModal,
	ReserveModal,
	AnnouncementDisplayModal,
	NewSwitch,
	ValidManagerCodeModal,
	FullHeightContainer,
	MyItemStockModal,
	NewCheckBox,
	SearchTextInput,
	LabelSelect,
	LabelTextField,
	CashDrawerRecordModal,
	MaintenanceModal,
	AutoCompleteSelect,
	QuantityCounter,
	NetworkDisconnectModal,
]

export default Vue => {
	components.forEach(e => {
		Vue.component(e.name, e)
	})
}
