export default {
	namespaced: true,
	state: {
		token: false,
		xsrfToken: '',
		userId: 0,
		storeId: null,
		storeNum: null,
		storeName: '',
		permissions: [],
		loginRole: '',
		authority: '',
		switchRole: '',
		selectedMenu: '',
		loginStoreId: null,
		reload: false,
		shopOrderStatus: true,
		buyerType: null,
		account: null,
		manager: true,
		super: false,
		hasPos: false,
		posMac: '',
		posMacSecond: '',
		posDeviceId: '',
		posDeviceIdSecond: '',
		hasTag: false, //標籤機
		tagMac: '',
		tagDeviceId: '',
		rememberAccount: '',
		backPosCheckoutMode: '', // 後台結帳模式
		announcementDisplayList: [], //公告列表
		announcementDisplayShow: {
			status: false,
			id: null,
		}, //公告彈窗
		firstShowAnnouncement: true, //第一次登入
		mobile: false, //手機模式
		scaleFont: 2, //放大字體
		adminLinePaySwitch: false, // 總後台店家linePay支付開關
		idleOrderReminderSwitch: false, // 閒置訂單提醒開關
		idleOrderReminderMinutes: 30, // 閒置訂單提醒分鐘設定
		active: true, //當前是否focus app
		iosVersion: null, //目前IOS APP版本
		androidVersion: null, //目前Android APP版本
		invoiceSwitch: {
			admin: false, //總後台
			store: false, //店家
		}, //電子發票是否啟用
		deliveryPlatformSwitch: {
			admin: false, // 是否啟用外送平台
			uberEats: false, // 是否啟用UberEats
			uberInvoiceSwitch: false, // 是否啟用Uber電子發票
		},
		quantityKeyboardType: 'GENERAL', // 數量鍵盤樣式
		uberEatsStoreAuthorization: false, // Uber Eats 店家授權是否成功
		systemModule: {
			posOrder: false,
			frontOrder: false,
			mobileOrder: false,
		}, // 系統模組
		whatsEatSwitch: false, // whatsEat開關
		printClient: false,
		payMethods: [], // 店家付款方式
	},
	mutations: {
		setToken($, val) {
			$.token = val
		},
		setXsrfToken($, val) {
			$.xsrfToken = val
		},
		setUserId($, val) {
			$.userId = val
		},
		setStoreId($, val) {
			$.storeId = val
		},
		// setStoreNum($, val) {
		//   $.storeNum = val
		// },
		setStoreName($, val) {
			$.storeName = val
		},
		setPermissions($, val) {
			$.permissions = val
		},
		setLoginRole($, val) {
			$.loginRole = val
		},
		setAuthority($, val) {
			$.authority = val
		},
		setSwitchRole($, val) {
			$.switchRole = val
		},
		setSelectedMenu($, val) {
			$.selectedMenu = val
		},
		setLoginStoreId($, val) {
			$.loginStoreId = val
		},
		setReload($, val) {
			$.reload = val
		},
		setShopOrderStatus($, val) {
			$.shopOrderStatus = val
		},
		setBuyerType($, val) {
			$.buyerType = val
		},
		setAccount($, val) {
			$.account = val
		},
		setManager($, val) {
			$.manager = val
		},
		setSuper($, val) {
			$.super = val
		},
		setHasPos($, val) {
			$.hasPos = val
		},
		setPosMac($, val) {
			$.posMac = val
		},
		setPosDeviceId($, val) {
			$.posDeviceId = val
		},
		setPosMacSecond($, val) {
			$.posMacSecond = val
		},
		setPosDeviceIdSecond($, val) {
			$.posDeviceIdSecond = val
		},
		setHasTag($, val) {
			$.hasTag = val
		},
		setTagMac($, val) {
			$.tagMac = val
		},
		setTagDeviceId($, val) {
			$.tagDeviceId = val
		},
		setRememberAccount($, val) {
			$.rememberAccount = val
		},
		setBackPosCheckoutMode($, val) {
			$.backPosCheckoutMode = val
		},
		setBackPosTagMode($, val) {
			$.backPosTagMode = val
		},
		setAnnouncementDisplayList($, val) {
			$.announcementDisplayList = val
		},
		setAnnouncementDisplayShow($, val) {
			$.announcementDisplayShow = val
				? val
				: {
						status: !$.announcementDisplayShow,
						id: null,
				  }
		},
		setFirstShowAnnouncement($, val) {
			$.firstShowAnnouncement = val ? val : !$.firstShowAnnouncement
		},
		setMobile($, val) {
			$.mobile = val
		},
		setScaleFont($, val) {
			$.scaleFont = val
		},
		setQuantityKeyboardType($, val) {
			$.quantityKeyboardType = val
		},
		setAdminLinePaySwitch($, val) {
			$.adminLinePaySwitch = val
		},
		setIdleOrderReminderSwitch($, val) {
			$.idleOrderReminderSwitch = val
		},
		setIdleOrderReminderMinutes($, val) {
			$.idleOrderReminderMinutes = val
		},
		setActive($, val) {
			$.active = val
		},
		setIosVersion($, val) {
			$.iosVersion = val
		},
		setAndroidVersion($, val) {
			$.androidVersion = val
		},
		setInvoiceSwitch($, val) {
			$.invoiceSwitch = val
		},
		setSystemModule($, val) {
			$.systemModule = val
		},
		setDeliveryPlatformSwitch($, val) {
			$.deliveryPlatformSwitch = val
		},
		setUberEatsStoreAuthorization($, val) {
			$.uberEatsStoreAuthorization = val
		},
		setWhatsEatSwitch($, val) {
			$.whatsEatSwitch = val
		},
		setPrintClient($, val) {
			$.printClient = val
		},
		setPayMethods($, val) {
			$.payMethods = val
		},
	},
	actions: {},
	// modules: {}
}
