let mailListCache = null // 用來存儲緩存結果

export function fetchMailList($http, storeId) {
	// 如果有緩存，直接返回緩存的結果
	if (mailListCache) {
		return Promise.resolve(mailListCache)
	}

	// 如果沒有緩存，發送請求並緩存結果
	return $http.order.getMailList({ ...storeId }).then(res => {
		if (res.status) {
			mailListCache = res.data // 緩存請求結果
		}
		return mailListCache
	})
}

export function clearMailListCache() {
	mailListCache = null
}