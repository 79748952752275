<template>
	<div
		@click="click"
		:class="{ disabled: disabled }"
		:style="{
			userSelect: 'none',
			WebkitUserSelect: 'none',
			MozUserSelect: 'none',
			msUserSelect: 'none',
		}"
	>
		<div class="switch" v-if="!simple">
			<img
				class="switch-icon"
				v-if="!value"
				src="../assets/Switch.png"
				alt=""
			/>
			<img class="switch-icon" v-else src="../assets/SwitchOpen.png" alt="" />
		</div>
		<div class="switch-simple" v-else>
			<img
				class="switch-icon"
				v-if="!value"
				src="../assets/Switch-simple.png"
				alt=""
			/>
			<img
				class="switch-icon"
				v-else
				src="../assets/SwitchOpen-simple.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyNewSwitch',
	props: {
		value: {
			required: false,
			type: Boolean,
			default: false,
		},
		simple: {
			required: false,
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false, // 默認為未禁用
		},
	},

	computed: {},

	methods: {
		click() {
			if (!this.disabled) {
				// 如果未禁用，執行點擊邏輯
				this.$emit('input')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.disabled {
	cursor: not-allowed; /* 禁止指標 */
	opacity: 0.5; /* 灰度效果 */
	pointer-events: none; /* 禁用點擊事件 */
}

.switch {
	width: 82px;
	height: 36px;
	position: relative;
}

.switch-simple {
	width: 48px;
	height: 28px;
}

.switch-icon {
	height: 100%;
	width: 100%;
}
</style>
